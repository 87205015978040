<template>
  <div class="page-wrapper chiller-theme" style="margin-top: 71px;">
    <main class="page-content">
      <div class="container-fluid">
        <ul class="breadcrumb ProximaNovaRegular" style="margin-top: 12px">
          <li class="ProximaNovaBold">
            Settings
          </li>
          <li v-if="role === 'master_admin'">
            <router-link to="/settings/users">
              <span>Users</span>
            </router-link>
          </li>
          <li class="ProximaNovaBold">
            Brands
          </li>
        </ul>
      </div>
      <div class="container-fluid">
        <w-button
          :buttonText="'User Summary'"
          :buttonLoader="'none'"
          v-if="role === 'master_admin'"
          @buttonClicked="$router.push('/settings/users')"
        ></w-button>
        <w-button
          class="mr-3"
          :buttonText="'+ Add Brand'"
          :buttonLoader="addBrandLoader"
          @buttonClicked="addBrandHandler"
          :isDisable="addBrandLoader === 'loader' ? true : false"
          :class="{
            resetPasswordLink: addBrandLoader === 'loader' ? true : false,
          }"
          v-if="role !== 'user'"
        ></w-button>
      </div>
      <div class="container-fluid m-0 p-0 mt-5">
        <div class="m-5 content-center" style="justify-content: space-between;">
          <h2 class="ProximaNovaBold">Brand List</h2>
          <w-search @searchText="searchEvent($event)"></w-search>
        </div>
        <div class="mx-5 text-right" style="font-size: small;">
          <div>
            {{ rowsPrePage * currentPage - 4 }} -
            {{ rowsPrePage * (currentPage - 1) + tableDataValues.items.length }}
            of {{ totalRows }}
          </div>
        </div>
        <div class="m-5">
          <w-table
            :tableProperties="tableDataValues"
            :noDataText="'No Brand has been created yet'"
          />
          <div class="d-flex" style="justify-content: end;">
            <w-pagination
              :currentPage="currentPage"
              :perPage="rowsPrePage"
              :totalRows="totalRows"
              @getSelectedPageEvent="getSelectedPageEvent($event)"
            />
          </div>
        </div>
      </div>
      <AddBrandModal
        ref="addBrandModal"
        @createBrand="addBrandDetails($event)"
      />
    </main>
  </div>
</template>

<script>
import DataTable from "@/widgets/DataTable.vue";
import { UserManagement } from "@/services/UserManagementService";
import Pagination from "@/widgets/Pagination.vue";
import Button from "@/components/Profile/Button.vue";
import AddBrandModal from "@/components/Modal/AddBrandModal.vue";
import SearchBar from "@/widgets/Search.vue";
const userManagement = new UserManagement();
export default {
  components: {
    "w-table": DataTable,
    "w-pagination": Pagination,
    "w-button": Button,
    AddBrandModal,
    "w-search": SearchBar,
  },
  data() {
    return {
      rowsPrePage: 5,
      totalRows: 0,
      currentPage: 1,
      avilableBrand: [],
      tableDataValues: {
        fields: [
          {
            key: "brandName",
            label: "Brand Name",
            thClass: "thClassLeft",
            tdClass: "text-left",
          },
          {
            key: "creadtedBy",
            label: "Created By",
            thClass: "thClassLeft",
            tdClass: "text-left",
          },
          {
            key: "createdDate",
            label: "Created Date",
            thClass: "thClassLeft",
            tdClass: "text-left",
          },
        ],
        items: [],
      },
      sortFieldName: "",
      sortFieldDirection: "",
      searchedTextValue: "",
      role: "",
      addBrandLoader: "none",
    };
  },
  methods: {
    searchEvent(event) {
      this.searchedTextValue = event;
      if (event !== "") {
        this.currentPage = 1;
        this.getSearchedTable(event);
      } else {
        this.getBrandListTable();
      }
    },
    getBrandListTable() {
      if (this.role === "master_admin") {
        this.getBrandList();
      } else {
        this.getBrandForUser();
      }
    },
    getSearchedTable(e) {
      userManagement
        .searchBrandByBrandName(e, this.currentPage, this.rowsPrePage)
        .then((res) => {
          this.tableDataValues.items = [];
          this.tableDataValues.items = res.response.content;
          this.totalRows = res.response.totalElements;
        });
    },
    addBrandDetails(data) {
      userManagement.createNewBrand(data).then((res) => {
        console.log(res);
        this.$refs.addBrandModal.hideWelcomePage();
        this.getBrandListTable();
      });
    },
    getSelectedPageEvent(event) {
      if (this.searchedTextValue !== "") {
        this.currentPage = event;
        this.getSearchedTable(this.searchedTextValue);
      } else {
        if (event != this.currentPage) {
          this.currentPage = event;
          this.getBrandList();
        }
      }
    },
    getBrandList() {
      userManagement
        .getAllBrands(
          sessionStorage.getItem("subId"),
          this.currentPage,
          this.rowsPrePage
        )
        .then((res) => {
          this.tableDataValues.items = [];
          this.tableDataValues.items = res.response.content;
          this.totalRows = res.response.totalElements;
        });
    },
    addBrandHandler() {
      this.addBrandLoader = "loader";
      var allTools = JSON.parse(sessionStorage.getItem("tools"));
      var roles = [];
      if (sessionStorage.getItem("Role").toLowerCase() === "admin") {
        roles = [
          {
            text: "User",
            id: "user",
          },
        ];
      } else {
        roles = [
          {
            text: "Admin",
            id: "admin",
          },
          {
            text: "User",
            id: "user",
          },
        ];
      }
      var toolsData = [];
      for (var i = 0; i < allTools.length; i++) {
        toolsData.push({ id: allTools[i], label: allTools[i] });
      }
      userManagement.getAllBrandList().then((res) => {
        var brands = [];
        for (var i = 0; i < res.response.length; i++) {
          brands.push({
            id: res.response[i].id,
            label: res.response[i].brandName,
          });
        }
        this.addBrandLoader = "none";
        this.getBrands(roles, toolsData, brands);
      });
    },
    getBrands(roles, toolsData, brands) {
      userManagement
        .getAllBrandList(
          sessionStorage.getItem("subId"),
          sessionStorage.getItem("userId")
        )
        .then((res) => {
          for (var i = 0; i < res.response.length; i++) {
            this.avilableBrand.push(res.response[i].brandName.toLowerCase());
          }
          this.$refs.addBrandModal.open({
            title: "Add Brand",
            role: roles,
            tools: toolsData,
            brand: brands,
            allBrand: this.avilableBrand,
          });
        });
    },
    getBrandForUser() {
      userManagement
        .getUserBrands(
          sessionStorage.getItem("clientId"),
          this.currentPage,
          this.rowsPrePage
        )
        .then((res) => {
          this.tableDataValues.items = [];
          this.tableDataValues.items = res.response.content;
          this.totalRows = res.response.totalElements;
        });
    },
  },
  mounted() {
    this.role = sessionStorage.getItem("Role").toLowerCase();
    this.getBrandListTable();
  },
};
</script>

<style></style>
